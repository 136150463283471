<template>
  <div class="user-list">
    <TableHeaderContainer :fields="dataFields" :styles="styleObject"> </TableHeaderContainer>
    <TableRowContainer
      ref="users-table"
      :data="users"
      :styles="styleObject"
      :dataKeys="dataKeys"
      :selectedItem="selectedItem"
      :pathIdParam="'userId'"
      @selectedElement="setSelectedUser"
    >
    </TableRowContainer>
  </div>
</template>

<script>
import TableRowContainer from "@/components/TableRowsContainer.vue";
import TableHeaderContainer from "@/components/TableHeaderContainer.vue";
import ClearDetailInfoFromTable from "../../../Extend/ClearDetailInfoFromTable";
// import { confirmChange, errorMessage } from "../../../utils/ErrorMessages";

export default {
  props: { userInfo: Object, users: Array },
  name: "UserList",
  mixins: [ClearDetailInfoFromTable],
  components: {
    TableHeaderContainer,
    TableRowContainer,
  },
  data() {
    return {
      allSelected: null,
      currentPage: 1,
      perPage: 10,
      companyNameFilter: null,
      statusFilter: null,
      companyNameOptions: [
        { value: "a", text: "Company" },
        { value: "b", text: "Factory" },
        { value: "c", text: "Guane" },
      ],
      statusOptions: [
        { value: "a", text: "Active" },
        { value: "b", text: "Inactive" },
      ],
      dataUser: [],
      dataFields: [
        { key: "name", label: "name" },
        { key: "lastname", label: "lastName" },
        { key: "role", label: "role" },
        { key: "is_active", label: "status" },
      ],
      dataKeys: ["first_name", "lastname", "role_name", "is_active"],
      styleObject: {
        width: "25%",
      },
      selectedItem: null,
      accountId: null,
    };
  },
  created() {
    this.userIdRoute();
  },
  watch: {
    users: {
      handler() {
        this.$nextTick(() => {
          this.scrollToStartUsers();
        });
      },
    },
  },
  methods: {
    userIdRoute() {
      if (!this.$route.params.userId) {
        const selectedItem = this.users[0];
        this.selectedItem = selectedItem;
      } else {
        this.selectedItem = this.users.find(
          (user) => user.id === parseInt(this.$route.params.userId, 10)
        );
      }
    },
    scrollToStartUsers() {
      this.$refs["users-table"].scrollToStartElements();
    },
    setSelectedUser(data) {
      if (data) {
        const params = { userId: data.id };
        this.$router.push({ params }).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
        this.$emit("selectedUser", data);
      }
    },
    addNewUserInData(data) {
      // el endpoint no trae info del company_name y company_address
      // data.company_name = this.dataUser[0].company_name;
      // data.company_address = this.dataUser[0].company_address;
      this.users.push(data);
    },
    deletedUserInData(data) {
      const index = this.dataUser.findIndex((el) => el.id === data);
      this.dataUser.splice(index, 1);
    },
  },
  computed: {
    rows() {
      return this.dataUser.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  width: 100%;
  height: 100%;
  padding: 2px;
  position: absolute;
}

</style>
