var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.userInfo && _vm.users)?_c('div',{staticClass:"details"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showID),expression:"showID"}],staticClass:"details__title"},[_c('span',[_vm._v("ID. "+_vm._s(_vm.userInformation.id))])]),_c('div',{staticClass:"details-editable"},[(!_vm.enableEditing)?_c('button',{staticClass:"details-editable__account",on:{"click":_vm.editInformation}},[_vm._v(" "+_vm._s(_vm.$t("accountManagement.editInformation"))+" ")]):_vm._e()]),_c('div',{staticClass:"details-inputs"},[_c('b-overlay',{attrs:{"show":_vm.loadingSaveInfo,"rounded":"sm"}},[_c('div',{staticClass:"details-inputs__col"},[_c('div',{staticClass:"details-inputs__group"},[_c('label',{staticClass:"details-inputs__label"},[_vm._v(" "+_vm._s(_vm.$t("userManagement.firstName"))+" ")]),(_vm.enableEditing)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInformation.firstName),expression:"userInformation.firstName"}],class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input',attrs:{"disabled":"","type":"text"},domProps:{"value":(_vm.userInformation.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userInformation, "firstName", $event.target.value)}}}):_vm._e(),(!_vm.enableEditing)?_c('span',{class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input'},[_vm._v(" "+_vm._s(_vm.userInformation.firstName)+" ")]):_vm._e()]),_c('div',{staticClass:"details-inputs__group"},[_c('label',{staticClass:"details-inputs__label"},[_vm._v(" "+_vm._s(_vm.$t("userManagement.lastName"))+" ")]),(_vm.enableEditing)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInformation.lastName),expression:"userInformation.lastName"}],class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input',attrs:{"disabled":"","type":"text"},domProps:{"value":(_vm.userInformation.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userInformation, "lastName", $event.target.value)}}}):_vm._e(),(!_vm.enableEditing)?_c('span',{class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input'},[_vm._v(" "+_vm._s(_vm.userInformation.lastName)+" ")]):_vm._e()]),(_vm.userInformation.phoneNumber)?_c('div',{staticClass:"details-inputs__group"},[_c('label',{staticClass:"details-inputs__label"},[_vm._v(" "+_vm._s(_vm.$t("userManagement.phoneNumber"))+" ")]),(_vm.enableEditing)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInformation.phoneNumber),expression:"userInformation.phoneNumber"}],class:_vm.enableEditing ? 'details-inputs__input-enabled' : 'details-inputs__input',attrs:{"disabled":!_vm.enableEditing,"type":"text"},domProps:{"value":(_vm.userInformation.phoneNumber)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.userInformation, "phoneNumber", $event.target.value)},function($event){return _vm.assignFormattedNumber()}]}}):_vm._e(),(!_vm.enableEditing)?_c('span',{class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input'},[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(_vm.userInformation.phoneNumber))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"details-inputs__col"},[_c('div',{staticClass:"details-inputs__group"},[_c('label',{staticClass:"details-inputs__label"},[_vm._v(" "+_vm._s(_vm.$t("userManagement.email"))+" ")]),(_vm.enableEditing)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInformation.email),expression:"userInformation.email"}],class:_vm.enableEditing ? 'details-inputs__input-enabled' : 'details-inputs__input',attrs:{"disabled":!_vm.enableEditing,"type":"text"},domProps:{"value":(_vm.userInformation.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userInformation, "email", $event.target.value)}}}):_vm._e(),(!_vm.enableEditing)?_c('span',{class:_vm.enableEditing
                  ? 'details-inputs__input-enabled disabled-input'
                  : 'details-inputs__input'},[_vm._v(" "+_vm._s(_vm.userInformation.email)+" ")]):_vm._e()]),(_vm.enableEditing)?_c('div',{staticClass:"details-inputs__group"},[_c('label',{staticClass:"details-inputs__label"},[_vm._v(" "+_vm._s(_vm.$t("userManagement.role"))+" ")]),_c('RCSelect',{key:_vm.userInformation.role,staticClass:"input-container",attrs:{"suggestions":_vm.rolesOptions,"label":"Role"},model:{value:(_vm.userInformation.role),callback:function ($$v) {_vm.$set(_vm.userInformation, "role", $$v)},expression:"userInformation.role"}})],1):_vm._e(),(_vm.enableEditing)?_c('div',{staticClass:"details-inputs__status-switch"},[_c('el-switch',{model:{value:(_vm.userInformation.isActive),callback:function ($$v) {_vm.$set(_vm.userInformation, "isActive", $$v)},expression:"userInformation.isActive"}}),_c('label',[_vm._v(" Status: "+_vm._s(_vm.userInformation.isActive ? "active" : "inactive")+" ")])],1):_vm._e()]),(_vm.enableEditing)?_c('div',{staticClass:"section-buttons"},[(_vm.$options.filters.checkPermission('delete:user'))?_c('button',{staticClass:"section-buttons__on-off",on:{"click":_vm.deleteUser}},[_c('i',{staticClass:"ion ion-trash-outline"}),_vm._v(_vm._s(_vm.$t("userManagement.delete"))+" ")]):_vm._e(),_c('button',{staticClass:"section-buttons__cancel",on:{"click":_vm.cancelEditInformation}},[_c('i',{staticClass:"ion ion-close-outline"}),_vm._v(_vm._s(_vm.$t("userManagement.cancel"))+" ")]),(_vm.$options.filters.checkPermission('patch:user'))?_c('button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingSaveInfo),expression:"loadingSaveInfo"}],staticClass:"section-buttons__save",on:{"click":_vm.saveEditableInformation}},[_c('i',{staticClass:"ion ion-create-outline"}),_vm._v(_vm._s(_vm.$t("userManagement.save"))+" ")]):_vm._e()]):_vm._e()])],1)]):_vm._e(),(!_vm.userInfo)?_c('div',{staticClass:"choose-location"},[_c('i',{staticClass:"ion ion-person-circle-outline"}),_c('p',[_vm._v("Choose an user")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }