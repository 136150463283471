<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div class="new-user-modal">
        <div class="new-user__content">
          <div class="new-user__header">
            <div class="new-user__title">
              <h3>{{ $t("userManagement.newUser") }}</h3>
            </div>
            <div class="quote-acceptance__button">
              <button @click="closeModal" class="close-button d-flex flex-row-reverse">
                <i class="ion ion-close-circle-outline close-button__icon"></i>
              </button>
            </div>
          </div>
          <hr />
          <div class="new-user__form-container">
            <div class="new-user__form-container-inputs">
              <RCInput
                :class="emptyFields['firstname'] === true ? 'missing-field' : ''"
                class="input-container"
                :label="$t('userManagement.firstName')"
                :type="'text'"
                :floatLabel="true"
                :requiredField="true"
                v-model="user.firstname"
                :minlength="inputLimits.first_name.min"
                :maxlength="inputLimits.first_name.max"
                @input="handleMissingStyles(user.firstname, 'firstname')"
              />
              <RCInput
                :class="emptyFields['lastname'] === true ? 'missing-field' : ''"
                class="input-container"
                :label="$t('userManagement.lastName')"
                :type="'text'"
                :floatLabel="true"
                :requiredField="true"
                v-model="user.lastname"
                :minlength="inputLimits.last_name.min"
                :maxlength="inputLimits.last_name.max"
                @input="handleMissingStyles(user.lastname, 'lastname')"
              />
              <RCInput
                class="input-container"
                :class="emptyFields['email'] === true ? 'missing-field' : ''"
                :label="$t('createAccount.email')"
                :type="'email'"
                :floatLabel="true"
                :requiredField="true"
                v-model="user.email"
                :minlength="inputLimits.email.min"
                :maxlength="inputLimits.email.max"
                @input="handleMissingStyles(user.email, 'email')"
              />
              <RCInput
                class="input-container"
                :label="$t('userManagement.phone')"
                :type="'text'"
                :floatLabel="true"
                v-model="user.phone"
                :minlength="inputLimits.phone.min"
                :maxlength="inputLimits.phone.max"
                @input="assignFormattedNumber()"
              />
              <RCInput
                class="input-container"
                :label="$t('userManagement.extension')"
                :type="'number'"
                :floatLabel="true"
                v-model="user.extension"
              />
              <RCSelect
                v-model="user.role"
                class="input-container"
                :suggestions="rolesOptions"
                label="Role"
                :floatLabel="true"
                :key="user.role"
              />

              <div
                class="input-password-content"
                id="target-restrictions"
                :class="emptyFields['password'] === true ? 'missing-field' : ''"
              >
                <input
                  @focus="showPopover = true"
                  @blur="showPopover = false"
                  @input="handleMissingStyles(user.password, 'password')"
                  :placeholder="$t('userManagement.password')"
                  :type="typeNewPassword"
                  class="input-password"
                  :class="emptyFields['password'] === true ? 'missing-field' : ''"
                  v-model.trim="user.password"
                  :minlength="inputLimits.password.min"
                  :maxlength="inputLimits.password.max"
                  name="new-password"
                  id="new-password"
                  tabindex="14"
                  autocomplete="one-time-code"
                />
                <button
                  class="see-password-button"
                  type="button"
                  @click="changeTypeOfInput('new-password')"
                >
                  <i :class="iconNewPassword"></i>
                </button>
              </div>

              <span v-show="passwordNotMatch" class="password-error"
                >* {{ $t("userManagement.notmatch") }}</span
              >
              <span v-show="passwordIsStrong && !passwordNotMatch" class="password-error"
                >* Enter a valid password</span
              >
              <PasswordPopover
                class="password-popover"
                :showPopover="showPopover"
                :restriction="restriction"
                :target="'target-restrictions'"
              />
              <div
                class="input-password-content"
                :class="emptyFields['confirmPassword'] === true ? 'missing-field' : ''"
              >
                <input
                  @input="handleMissingStyles(user.confirmPassword, 'confirmPassword')"
                  :placeholder="$t('userManagement.confirmPassword')"
                  :type="typeConfirmPassword"
                  class="input-password"
                  :class="emptyFields['confirmPassword'] === true ? 'missing-field' : ''"
                  v-model.trim="user.confirmPassword"
                  :minlength="inputLimits.password.min"
                  :maxlength="inputLimits.password.max"
                  name="confirm-password"
                  id="confirm-password"
                  tabindex="15"
                  autocomplete="one-time-code"
                />
                <button
                  class="see-password-button"
                  type="button"
                  @click="changeTypeOfInput('confirm-password')"
                >
                  <i :class="iconConfirmPassword"></i>
                </button>
              </div>
            </div>
            <div class="button-container">
              <button class="button-container--cancel" @click="closeModal()">
                {{ $t("userManagement.cancel") }}
              </button>
              <button class="button-container--save" @click="createUserByAccountId()">
                {{ $t("userManagement.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import changeTypePasswords from "@/Extend/changeTypePasswords";
import { errorMessage } from "../../../utils/ErrorMessages";
import RCInput from "../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../components/RCComponents/CustomRCSelect.vue";
import ValidateEmptyFields from "../../../utils/validateEmptyFields";
import PasswordPopover from "../../../components/passwordValidationsPopover.vue";

export default {
  name: "NewUserModal",
  components: {
    RCInput,
    PasswordPopover,
    RCSelect,
  },
  mixins: [changeTypePasswords, FormatPhoneNumber],
  data() {
    return {
      loading: false,
      image: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      roles: null,
      rolesOptions: [],
      emptyFields: {
        email: false,
        firstname: false,
        lastname: false,
        username: false,
        password: false,
        confirmPassword: false,
      },
      passwordError: false,
      user: {
        username: "",
        email: "",
        firstname: "",
        lastname: "",
        phone: null,
        extension: null,
        is_active: true,
        password: "",
        confirmPassword: "",
        photo_url: null,
        role: null,
      },
      loadingSaveUser: false,
      showPopover: false,
      // Inputs limits
      inputLimits: {
        first_name: {
          min: 1,
          max: 25,
        },
        last_name: {
          min: 1,
          max: 30,
        },
        phone: {
          min: 7,
          max: 12,
        },
        email: {
          min: 11,
          max: 100,
        },
        password: {
          min: 8,
          max: 30,
        },
      },
    };
  },
  created() {
    this.getRoles();
    this.rolesOptions = this.roles
      .filter((rol) => rol.id === 2 || rol.id === 4 || rol.id === 2001)
      .map((rol) => {
        const item = {
          id: rol.id,
          label: rol.name,
          value: rol.name,
        };
        return item;
      });
    this.user.role = this.rolesOptions[0].label;
  },
  watch: {
    "user.phone": {
      handler() {
        this.assignFormattedNumber();
      },
    },
  },
  methods: {
    assignFormattedNumber() {
      const formattedPhoneNumber = this.formatPhoneNumber(this.user.phone);
      if (formattedPhoneNumber && formattedPhoneNumber.length > 10) {
        this.user.phone = formattedPhoneNumber;
      }
    },
    selectedImage(event) {
      const image = event.target.files || event.dataTransfer.files;
      this.user.photo_url = image;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    resetEmptyFields() {
      Object.keys(this.emptyFields).forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    validationsUserByAccountId() {
      this.resetEmptyFields();
      const matchPassword = this.passwordsMatch();
      const strongPassword = this.strongPassword();
      const validEmail = this.validateEmail();
      const formObject = { ...this.user };
      delete formObject.phone;
      delete formObject.extension;
      delete formObject.username;
      delete formObject.photo_url;
      const formValidation = new ValidateEmptyFields(formObject);
      formValidation.emptyFields.forEach((key) => {
        this.emptyFields[key] = true;
      });
      if (formValidation.validation && matchPassword && validEmail && strongPassword) {
        return true;
      }
      return false;
    },
    validateEmail() {
      // eslint-disable-next-line max-len
      const regexp = new RegExp(
        /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (regexp.test(this.user.email)) {
        return true;
      }
      this.emptyFields.email = true;
      return false;
    },
    async validateEmailExist() {
      const response = await this.$store.dispatch("user/validateIfTheEmailExist", {
        username: this.user.email.toLowerCase(),
      });
      if (!response) {
        errorMessage("The email address you entered is already in use. Please use a different email or log in with your existing account.");
      }
      return response;
    },
    async createUserByAccountId() {
      if (this.validationsUserByAccountId()) {
        this.loading = true;
        const validateEmail = await this.validateEmailExist();
        if (!validateEmail) {
          this.loading = false;
          return;
        }
        const data = {
          id: this.$route.params.accountId,
          body: {
            username: this.user.email.split("@")[0],
            email: this.user.email,
            first_name: this.user.firstname.charAt(0).toUpperCase() + this.user.firstname.slice(1),
            lastname: this.user.lastname.charAt(0).toUpperCase() + this.user.lastname.slice(1),
            phone: this.user.phone,
            extension: this.user.extension,
            is_active: this.user.is_active,
            photo_url: this.user.photo_url,
            password: this.user.password,
            confirm_password: this.user.confirmPassword,
            role_id: this.rolesOptions.find((role) => role.label === this.user.role).id,
          },
        };
        const response = await this.$store.dispatch("account/createUserByAccountId", data);
        if (response) {
          const newUserData = { ...response.user };
          newUserData.status = newUserData.is_active;
          newUserData.is_active = newUserData.is_active === true ? "Active" : "Inactive";
          newUserData.role_name = this.roles.find((role) => role.id === newUserData.role_id).name;
          this.$emit("reloadUserInfo", newUserData);
          this.loading = false;
          this.$emit("closeModal");
        }
        this.loading = false;
      }
    },
    getRoles() {
      this.roles = this.$store.getters["role/getRoles"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/RCComponents.scss";

.close-button {
  @include modals-close-button;
}
.new-user {
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
    font-family: $font-family-portal;
    font-weight: 500;
  }
  &__image {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    &--container {
      margin-left: 6%;
    }
  }
}
hr {
  margin-top: 0;
}
::v-deep .el-avatar {
  border: 1px solid $color-border-container;
  width: 150px;
  height: 150px;
}
input[type="file"] {
  display: none;
}
label {
  cursor: pointer;
  position: relative;
  bottom: 18px;
  right: 40px;
  display: inline-block;
  background: $color-border-container;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
    vertical-align: middle;
    margin: auto;
  }
}
.new-user__form-container-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: space-between;
  position: relative;
  font-family: $font-family-portal;
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.input-container {
  margin: 7px 0px;
}
.input-password-content {
  display: flex;
  height: 40px;
  margin: 10px 0px 10px 0px;
  padding: 10px 0px 10px 3px;
  background: $color-white;
  border: 2px solid rgb(221, 221, 221);
  border-radius: 10px;
  opacity: 1;
  color: $color-primary-company;
  font-family: $font-family-portal;
  outline: none;
  text-align: center;
  width: 100%;
  border: 1px solid $color-border-container;
  box-shadow: none;
}
.input-password {
  @include input-password;
  font-size: 1rem;
}
.see-password-button {
  @include icon-input;
  @extend %flex-center-center;
  margin-right: 0.313rem;
}
.button-container {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  font-family: $font-family-portal;
  &--save {
    @include primary-button;
    background-color: $color-save-button;
    padding: 7px;
    width: 47.5%;
    &:hover {
      background-color: $color-save-button;
    }
  }
  &--cancel {
    @include opposite-secondary-button;
    @include secondary-button();
    outline: 0;
    padding: 7px;
    width: 47.5%;
  }
}
.password-error {
  color: red;
  bottom: -11px;
  font-size: 0.77rem;
  font-weight: 500;
  position: absolute;
}
.password-popover {
  position: absolute;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
  inset: -4px !important;
}
</style>
