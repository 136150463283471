export default {
  data() {
    return {
      spinner: true,
    };
  },
  methods: {
    clearUserInfo() {
      this.$emit("clearUserInformation", null);
    },
    clearAccountInfo() {
      this.$emit("clearAccountInformation", null);
      this.spinner = true;
    },
  },
};
