<template>
  <div>
    <div v-if="userInfo && users" class="details">
      <div class="details__title" v-show="showID">
        <span>ID. {{ userInformation.id }}</span>
      </div>
      <div class="details-editable">
        <button v-if="!enableEditing" @click="editInformation" class="details-editable__account">
          {{ $t("accountManagement.editInformation") }}
        </button>
      </div>
      <div class="details-inputs">
        <b-overlay :show="loadingSaveInfo" rounded="sm">
          <div class="details-inputs__col">
            <div class="details-inputs__group">
              <label class="details-inputs__label">
                {{ $t("userManagement.firstName") }}
              </label>
              <input
                v-if="enableEditing"
                disabled
                type="text"
                v-model="userInformation.firstName"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              />
              <span
                v-if="!enableEditing"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              >
                {{ userInformation.firstName }}
              </span>
            </div>
            <div class="details-inputs__group">
              <label class="details-inputs__label">
                {{ $t("userManagement.lastName") }}
              </label>
              <input
                v-if="enableEditing"
                disabled
                type="text"
                v-model="userInformation.lastName"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              />
              <span
                v-if="!enableEditing"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              >
                {{ userInformation.lastName }}
              </span>
            </div>
            <div class="details-inputs__group" v-if="userInformation.phoneNumber">
              <label class="details-inputs__label">
                {{ $t("userManagement.phoneNumber") }}
              </label>
              <input
                v-if="enableEditing"
                :disabled="!enableEditing"
                type="text"
                v-model="userInformation.phoneNumber"
                :class="enableEditing ? 'details-inputs__input-enabled' : 'details-inputs__input'"
                @input="assignFormattedNumber()"
              />
              <span
                v-if="!enableEditing"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              >
                {{ formatPhoneNumber(userInformation.phoneNumber) }}
              </span>
            </div>
          </div>
          <div class="details-inputs__col">
            <div class="details-inputs__group">
              <label class="details-inputs__label">
                {{ $t("userManagement.email") }}
              </label>
              <input
                v-if="enableEditing"
                :disabled="!enableEditing"
                type="text"
                v-model="userInformation.email"
                :class="enableEditing ? 'details-inputs__input-enabled' : 'details-inputs__input'"
              />
              <span
                v-if="!enableEditing"
                :class="
                  enableEditing
                    ? 'details-inputs__input-enabled disabled-input'
                    : 'details-inputs__input'
                "
              >
                {{ userInformation.email }}
              </span>
            </div>
            <div class="details-inputs__group" v-if="enableEditing">
              <label class="details-inputs__label">
                {{ $t("userManagement.role") }}
              </label>
              <RCSelect
                v-model="userInformation.role"
                class="input-container"
                :suggestions="rolesOptions"
                label="Role"
                :key="userInformation.role"
              />
            </div>
            <div class="details-inputs__status-switch" v-if="enableEditing">
              <el-switch v-model="userInformation.isActive"> </el-switch>
              <label>
                Status:
                {{ userInformation.isActive ? "active" : "inactive" }}
              </label>
            </div>
          </div>
          <div class="section-buttons" v-if="enableEditing">
            <button
              class="section-buttons__on-off"
              v-if="$options.filters.checkPermission('delete:user')"
              @click="deleteUser"
            >
              <i class="ion ion-trash-outline"></i>{{ $t("userManagement.delete") }}
            </button>
            <button @click="cancelEditInformation" class="section-buttons__cancel">
              <i class="ion ion-close-outline"></i>{{ $t("userManagement.cancel") }}
            </button>
            <button
              v-if="$options.filters.checkPermission('patch:user')"
              class="section-buttons__save"
              @click="saveEditableInformation"
              v-loading="loadingSaveInfo"
            >
              <i class="ion ion-create-outline"></i>{{ $t("userManagement.save") }}
            </button>
          </div>
        </b-overlay>
      </div>
    </div>
    <div v-if="!userInfo" class="choose-location">
      <i class="ion ion-person-circle-outline"></i>
      <p>Choose an user</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import RCSelect from "../../../components/RCComponents/CustomRCSelect.vue";
import ClearDetailInfo from "../../../Extend/ClearDetailInfo";
import { generalMessage } from "../../../utils/ErrorMessages";

export default {
  props: {
    userInfo: Object,
    showIdDetail: Boolean,
    users: Array,
    roles: Array,
  },
  components: {
    RCSelect,
  },
  mixins: [ClearDetailInfo, FormatPhoneNumber],
  name: "UserDetails",
  data() {
    return {
      enableEditing: false,
      userInformation: {
        id: "",
        companyName: "",
        companyAddress: "",
        phoneNumber: "",
        taxId: "",
        firstName: "",
        lastName: "",
        accounting: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryCode: "",
        isActive: true,
      },
      showID: true,
      loadingSaveInfo: false,
      rolesOptions: [],
    };
  },
  created() {
    this.showID = this.showIdDetail;
  },
  computed: {
    ...mapGetters({ usersData: "user/getUsers" }),
  },
  watch: {
    userInfo: {
      immediate: true,
      async handler() {
        this.rolesOptions = this.$store.getters["role/getRoles"]
          .filter((rol) => rol.id === 2 || rol.id === 4 || rol.id === 2001)
          .map((rol) => {
            const item = {
              id: rol.id,
              label: rol.name,
              value: rol.name,
            };
            return item;
          });
        if (this.userInfo) {
          this.enableEditing = false;
          this.fillUserInfoFields(this.userInfo);
        }
      },
    },
    'userInformation.phoneNumber': {
      handler() {
        this.assignFormattedNumber();
      }
    },
    usersData() {
      if (this.userInfo) {
        this.fillUserInfoFields(this.usersData[0]);
      }
    },
  },
  methods: {
    assignFormattedNumber() {
      const formattedPhoneNumber = this.formatPhoneNumber(this.userInformation.phoneNumber);
      if (formattedPhoneNumber && formattedPhoneNumber.length > 10) {
        this.userInformation.phoneNumber = formattedPhoneNumber;
      }
    },
    fillUserInfoFields(userInfo) {
      this.userInformation = {
        id: userInfo.id,
        companyName: userInfo.company_name,
        companyAddress: userInfo.company_address,
        phoneNumber: userInfo.phone,
        taxId: "",
        firstName: userInfo.name || userInfo.first_name,
        lastName: userInfo.lastname,
        username: userInfo.username,
        role: userInfo.role_name,
        email: userInfo.email,
        countryCode: userInfo.country,
        isActive: userInfo.status,
      };
    },
    editInformation() {
      this.enableEditing = true;
    },
    cancelEditInformation() {
      this.enableEditing = false;
    },
    async saveEditableInformation() {
      if (this.$options.filters.checkPermission("patch:user")) {
        if (this.userInformation.email === "") {
          this.swal({
            title: "The email field is required",
            icon: "warning",
          });
          return;
        }
        this.loadingSaveInfo = true;
        const payload = {
          id: parseInt(this.$route.params.userId, 10),
          body: {
            ...(this.userInformation.phoneNumber && {
              phone: this.phoneNumberWithoutFormat(this.userInformation.phoneNumber),
            }),
            ...(this.userInformation.email && {
              email: this.userInformation.email,
            }),
            username: this.userInformation.username,
            is_active: this.userInformation.isActive,
            role_id: this.rolesOptions.find((role) => role.label === this.userInformation.role).id,
          },
        };
        await this.$store.dispatch("user/updateUserInfo", payload);
        payload.accountId = parseInt(this.$route.params.accountId, 10);
        this.updateInfoInVuex(payload);
        generalMessage("User successfully updated");
        this.enableEditing = false;
        this.loadingSaveInfo = false;
      }
    },
    async deleteUser() {
      if (this.$options.filters.checkPermission("delete:user")) {
        this.loadingSaveInfo = true;
        const id = this.$route.params.userId;
        await this.$store.dispatch("user/deleteUser", id);
        this.deleteAccountFromVuex({
          id,
          accountId: parseInt(this.$route.params.accountId, 10),
        });
        this.$emit("deletedUser", parseInt(id, 10));
        generalMessage("User removed");
        this.enableEditing = false;
        this.loadingSaveInfo = false;
      }
    },
    updateInfoInVuex(payload) {
      payload.body.is_active = payload.body.is_active ? "Active" : "Inactive";
      payload.body.status = this.userInformation.isActive;
      payload.body.role_name = this.rolesOptions.find(
        (role) => role.id === payload.body.role_id
      ).label;
      this.$store.commit("account/updateUserAccount", payload);
    },
    deleteAccountFromVuex(payload) {
      this.$store.commit("account/deleteUserAccount", payload, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/AccountDetails.scss";
.details-inputs__input {
  width: 100%;
}
.details-inputs__status-switch {
  @include font-standart-text;
  display: flex;
  margin-bottom: 10px;
  label {
    margin-left: 10px;
  }
}
::v-deep .ion-person-circle-outline:before {
  font-size: 110px;
}
::v-deep .el-loading-mask {
  border-radius: 15px;
}
::v-deep {
  .active {
    span {
      border-color: $color-border-container !important;
      background-color: $color-border-container !important;
    }
  }
  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
}
::v-deep {
  .rc-select-container {
    @include input-information;
    @include font-standart-text;
    height: 40px;
    width: 100%;
    padding-left: 15px;
    &__selected-option {
      font-size: 14px;
      padding: 0.44rem;
    }
    &__text {
      font-family: $font-family-portal;
      color: black !important;
    }
    &__options-container {
      width: 95%;
    }
    & .rc-select__selected-option,
    .rc-suggestions-list__item {
      font-size: 14px;
    }
  }
}
::v-deep {
  .bg-light {
    background-color: $color-gray-light !important;
  }
}
</style>
