export default {
  data() {
    return {
      name: "",
      userInfo: {},
      loading: true,
      screenWidth: null,
      showNewUserModel: false,
      accountId: null,
      users: null,
      account: null,
      skip: 0,
      roles: [],
    };
  },
  async created() {
    this.accountId = parseInt(this.$route.params.accountId, 10);
    await this.getRoles();
    this.setupUsersAccount();
  },
  mounted() {
    this.sizingHeigthScreen();
  },
  methods: {
    async setupUsersAccount() {
      let currentAccount = this.$store.getters["account/getOneAccount"](this.accountId);
      if (!currentAccount) {
        currentAccount = await this.$store.dispatch("account/getAccountById", this.accountId);
      }
      if (!currentAccount.users) {
        this.users = await this.getUsers(currentAccount);
      }
      this.users = this.$deepCopy(currentAccount.users);
      this.$store.commit("account/setAccountNameUserView", currentAccount.company_name);
      this.disableLoading();
    },
    searchCurrentAccountInAccountsList(accounts) {
      const currentAccount = accounts.find((account) => account.id === this.accountId);
      return currentAccount;
    },
    async getAccounts(payload) {
      if (this.$options.filters.checkPermission("get:accounts")) {
        const accounts = await this.$store.dispatch("account/getAllAccounts", payload);
        return accounts;
      }
      return null;
    },
    async getUsers(account) {
      const accountUsers = await this.$store.dispatch(
        "account/getAllUsersByAccountId",
        this.accountId
      );
      const users = accountUsers.map((item) => ({
        ...item,
        company_name: account.company_name,
        company_address: account.company_address,
        role_name: item.role.name,
        status: item.is_active,
        is_active: item.is_active ? "Active" : "Inactive",
      }));
      this.$store.commit("account/setUsersToAnAccount", { id: this.accountId, users });
      return users;
    },
    async getRoles() {
      this.roles = await this.$store.dispatch("role/getAllRoles");
    },
    handleSelectedUser(data) {
      this.userInfo = data;
    },
    reloadUserInfo(data) {
      this.users.push(data);
    },
    deletedUser(userId) {
      this.users = this.users.filter((user) => user.id !== userId);
      this.userInfo = null;
    },
    disableLoading() {
      this.loading = false;
    },
    handleActiveRow(activeRowStatus) {
      this.userInfo = activeRowStatus;
    },
    sizingHeigthScreen() {
      setTimeout(() => {
        if (this.screenWidth >= 801) {
          const userListContainer = document.getElementById("user-content");
          this.setupScrollableDiv(userListContainer, userListContainer.offsetTop);
        }
      }, 0);
    },
    async changeView(routeName) {
      await this.$router.push({
        name: routeName,
      });
    },
    createNewUserModal() {
      this.showNewUserModel = true;
    },
    closeNewUserModal() {
      this.showNewUserModel = false;
    },
  },
};
